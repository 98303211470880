import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseText, ColorDark, Icon } from 'uangcermat-web-toolkit-v2';

const EmptyNotificationComponent = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className="flex flex-1 flex-col overflow-auto items-center justify-center">
      <Icon
        color={ColorDark.silver}
        dataTestId="iconContainer"
        name="emptyNotification"
        shapeColor={ColorDark.solitude}
        shapeType="circle"
        size="extra-large"
      />
      <div className="mt-2">
        <BaseText
          label={t('common:noNotification')}
          color={ColorDark.blackCoral}
          variant="small"
          className="text-center"
        />
      </div>
    </div>
  );
};

export default EmptyNotificationComponent;
