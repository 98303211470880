import {
  SidebarMenuDefaultInterface,
  SidebarMenuInterface
} from 'uangcermat-web-toolkit-v2/build/src/blocks/Sidebar/Sidebar.type';

const COMPANY_PERMISSION = ['setting.company', 'leave.setting', 'attendance.setting'];
const REPORT_PERMISSION = [
  'workSchedule.report',
  'payrollBpjs.list',
  'payrollPph.list',
  'employee.list',
  'leave.list'
];

export const getGuardedNavigation = ({
  userPermission,
  navConfig
}: {
  userPermission: Array<string>;
  navConfig: Array<SidebarMenuInterface>;
}) => {
  const finalNav: Array<SidebarMenuInterface> = [];
  const userPermissionTmp: Record<string, Array<string>> = { settings: [], report: [] };
  userPermission.map((nav) => {
    const menu = nav.split('.');
    const isValidMenu = ['payroll', 'employee', 'cooperative', 'setting'].includes(menu[0]);
    if (isValidMenu && userPermissionTmp[menu[1]] === undefined) {
      userPermissionTmp[menu[1]] = [`${menu[1]}.${menu[2]}`];
      if (COMPANY_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
        userPermissionTmp['settings'] = [...userPermissionTmp['settings'], `${menu[1]}.${menu[2]}`];
      }
      if (REPORT_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
        userPermissionTmp['report'] = [...userPermissionTmp['report'], `${menu[1]}.${menu[2]}`];
      }
    } else {
      if (userPermissionTmp[menu[1]]) {
        userPermissionTmp[menu[1]] = [...userPermissionTmp[menu[1]], `${menu[1]}.${menu[2]}`];
        if (COMPANY_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
          userPermissionTmp['settings'] = [
            ...userPermissionTmp['settings'],
            `${menu[1]}.${menu[2]}`
          ];
        }
        if (REPORT_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
          userPermissionTmp['report'] = [...userPermissionTmp['report'], `${menu[1]}.${menu[2]}`];
        }
      }
    }
  });

  navConfig.map((menu) => {
    if (userPermissionTmp[menu.id]) {
      const sub: Array<SidebarMenuDefaultInterface> = [];
      if (menu?.subMenu) {
        menu.subMenu.map((e) => {
          const submenuSplit = e.id.split('.');
          if (
            userPermissionTmp[submenuSplit[0]]?.includes(e.id) ||
            userPermissionTmp[submenuSplit[0]]?.includes(e.permission)
          ) {
            sub.push(e);
          } else if (
            submenuSplit[0] === 'attendance' &&
            userPermissionTmp['attendanceImport']?.includes(e.permission)
            // eslint-disable-next-line sonarjs/no-duplicated-branches
          ) {
            sub.push(e);
          }
        });
      }
      if (sub.length) finalNav.push({ ...menu, subMenu: sub });
    }
  });
  return finalNav;
};
