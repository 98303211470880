export const getInitialCompanyNameForFallbackAvatar = ({ name }: { name: string }) => {
  if (name) {
    const isHasCompanyOnCompanyNameThreeChar = [
      'PT.',
      'pt.',
      'PT ',
      'CV.',
      'CV ',
      'cv.',
      'cv ',
      'pt '
    ].includes(name.substring(0, 3));
    const isHasCompanyOnCompanyNameFourChar = ['PT. ', 'pt. ', 'CV. ', 'cv. '].includes(
      name.substring(0, 4)
    );

    if (isHasCompanyOnCompanyNameFourChar) {
      const newCompanyNameSplittedBySpace = name.substring(4).split(' ');
      const nextStringExist = newCompanyNameSplittedBySpace[1]
        ? newCompanyNameSplittedBySpace[1][0]
        : '';
      return newCompanyNameSplittedBySpace[0][0].toUpperCase() + nextStringExist;
    }

    if (isHasCompanyOnCompanyNameThreeChar) {
      const newCompanyNameSplittedBySpace = name.substring(3).split(' ');
      const nextStringExist = newCompanyNameSplittedBySpace[1]
        ? newCompanyNameSplittedBySpace[1][0]
        : '';
      return newCompanyNameSplittedBySpace[0][0].toUpperCase() + nextStringExist;
    }

    const splittedWordName = name.split(' ');
    return splittedWordName.length === 1
      ? splittedWordName[0][0].toUpperCase()
      : splittedWordName[0][0].toUpperCase() + splittedWordName[1][0].toUpperCase();
  }
  return '';
};
