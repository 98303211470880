import axios, { AxiosRequestConfig } from 'axios';

import {
  axiosBaseConfigOptions,
  axiosInterceptorRequest,
  axiosInterceptorResponseError
} from './axiosBaseConfig';

const defaultOptions: AxiosRequestConfig = {
  ...axiosBaseConfigOptions,
  baseURL: process.env.APP_COOPERATIVE_SERVICE_URL
};
export const axiosCooperativeServiceInstance = axios.create(defaultOptions);

axiosCooperativeServiceInstance.interceptors.request.use(axiosInterceptorRequest);

axiosCooperativeServiceInstance.interceptors.response.use(
  (response) => response,
  axiosInterceptorResponseError
);
