export { default as EnglishAttendanceSubmission } from './attendanceSubmission.json';
export { default as EnglishChangeEmail } from './changeEmail.json';
export { default as EnglishCheckInOut } from './checkInOut.json';
export { default as EnglishCommon } from './common.json';
export { default as EnglishCooperative } from './cooperative.json';
export { default as EnglishDailyAttendance } from './dailyAttendance.json';
export { default as EnglishDirectory } from './directory.json';
export { default as EnglishEmployee } from './employee.json';
export { default as EnglishEmployeeLeave } from './employeeLeave.json';
export { default as EnglishEmploymentStatus } from './employmentStatus.json';
export { default as EnglishLeaveHistory } from './leaveHistory.json';
export { default as EnglishLoginPage } from './login.json';
export { default as EnglishMember } from './member.json';
export { default as EnglishMemberDetail } from './memberDetail.json';
export { default as EnglishMenu } from './menu.json';
export { default as EnglishOnboarding } from './onboarding.json';
export { default as EnglishtPaycomponent } from './paycomponent.json';
export { default as EnglishPayroll } from './payroll.json';
export { default as EnglishPayrollEmployee } from './payrollEmployee.json';
export { default as EnglishEditProfile } from './profile.json';
export { default as EnglishReportBpjs } from './reportBpjs.json';
export { default as EnglishReportPph21 } from './reportPph21.json';
export { default as EnglishResetPassword } from './resetPassword.json';
export { default as EnglishResetPin } from './resetPin.json';
export { default as EnglishSettingApproval } from './settingApproval.json';
export { default as EnglishSettingAttendance } from './settingAttendance.json';
export { default as EnglishSettingsBranch } from './settingBranch.json';
export { default as EnglishSettingCompany } from './settingCompany.json';
export { default as EnglishSettingHoliday } from './settingHoliday.json';
export { default as EnglishSettingLeave } from './settingLeave.json';
export { default as EnglishSettingNotification } from './settingNotification.json';
export { default as EnglishSettingOvertime } from './settingOvertime.json';
export { default as EnglishWorkSchedule } from './workSchedule.json';
