import '../styles/font.css';
import '../../node_modules/uangcermat-web-toolkit-v2/build/index.css';
import '../styles/globals.css';

import AppContext from '@context/AppContext';
import { useI18n } from '@core/hooks/useI18n';
import SidebarLayout from '@layout/SidebarLayout';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createGlobalStyle } from 'styled-components';
import { ColorDark, ColorLight, Toast } from 'uangcermat-web-toolkit-v2';

export const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  position: absolute;
  background: ${ColorLight.whiteSolid};
  box-shadow: inset 0px 0px 17px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
  position: absolute;
  background: ${ColorDark.blackCoral}; 
  border-radius: 23px;
}

.scrollbar-thumb-dark-blackCoral {
  --scrollbar-thumb: ${ColorDark.blackCoral} !important;
  --scrollbar-track: inset 0px 0px 17px rgba(0, 0, 0, 0.25) !important;
}
`;

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

// eslint-disable-next-line react-hooks/rules-of-hooks
const { init } = useI18n();

init();

function MyApp({ Component, pageProps, err }: AppProps & { err: unknown }) {
  const [showChild, setShowChild] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  const ComponentWrapper = () => {
    return <Component {...pageProps} err={err} />;
  };

  return (
    <>
      <Head>
        <title>{`${process.env.APP_NAME} Client Portal`}</title>
      </Head>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AppContext>
          {['/', 'login', 'reset', 'change', 'company'].includes(router.pathname.split('/')[1]) ? (
            <ComponentWrapper />
          ) : (
            <SidebarLayout>
              <ComponentWrapper />
            </SidebarLayout>
          )}
          <Toast />
        </AppContext>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
