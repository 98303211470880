import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import { BaseResponseInterface } from '@interface/BaseResponseInterface';
import {
  NotificationGetUnreadPayloadInterface,
  PayloadGetAllNotification,
  ResponseGetNotification
} from '@interface/NotificationInterface';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

// Get All Notification service
const getAllNotification = async ({
  params
}: {
  params: PayloadGetAllNotification;
}): Promise<APIResponse<ResponseGetNotification[] | null>> => {
  const { data } = await axiosAuthInstance.get('notifications', { params });
  return data;
};

export const useGetAllNotification = ({
  params,
  options
}: {
  params: PayloadGetAllNotification;
  options?: UseQueryOptions<APIResponse<ResponseGetNotification[] | null>>;
}) => {
  return useQuery<APIResponse<ResponseGetNotification[] | null>>(
    ['useGetAllNotification'],
    () => getAllNotification({ params }),
    options
  );
};

// Get notification unread count service
const getUnreadCountService = async (
  params: NotificationGetUnreadPayloadInterface
): Promise<APIResponse<{ count: number }>> => {
  const { data } = await axiosAuthInstance.get('notifications/unreadCount', { params });
  return data;
};

export const useGetUnreadCountService = ({
  params,
  options
}: {
  params: NotificationGetUnreadPayloadInterface;
  options?: UseQueryOptions<APIResponse<{ count: number }>, APIError>;
}) => {
  return useQuery<APIResponse<{ count: number }>>(
    ['useGetNotificationUnreadCount', params.targetUserId],
    () => getUnreadCountService(params),
    options
  );
};

// Post MultipleRead service
const postMultipleReadService = async (params: {
  ids: string[];
}): Promise<APIResponse<BaseResponseInterface>> => {
  const { data } = await axiosAuthInstance.post('/notifications/multipleRead', params);
  return data;
};

export const usePostMultipleOrSingleRead = (
  mutateFn?: UseMutationOptions<APIResponse<BaseResponseInterface>, APIError, { ids: string[] }>
) => {
  return useMutation<APIResponse<BaseResponseInterface>, APIError, { ids: string[] }>(
    postMultipleReadService,
    mutateFn
  );
};

// Delete Notification Service

const postMultipleOrSingleDeletedNotificationService = async (params: {
  ids: string[];
}): Promise<APIResponse<BaseResponseInterface>> => {
  const { data } = await axiosAuthInstance.post('/notifications/multipleDelete', params);
  return data;
};

export const usePostMultipleOrSingleDeleteNotification = (
  mutateFn?: UseMutationOptions<APIResponse<BaseResponseInterface>, APIError, { ids: string[] }>
) => {
  return useMutation<APIResponse<BaseResponseInterface>, APIError, { ids: string[] }>(
    postMultipleOrSingleDeletedNotificationService,
    mutateFn
  );
};

// undo delete notification service
const postUndoMultipleOrSingleDeleteNotificationService = async (params: {
  ids: string[];
}): Promise<APIResponse<BaseResponseInterface>> => {
  const { data } = await axiosAuthInstance.post('/notifications/multipleUndoDelete', params);
  return data;
};

export const usePostUndoDeleteNotification = (
  mutateFn?: UseMutationOptions<APIResponse<BaseResponseInterface>, APIError, { ids: string[] }>
) => {
  return useMutation<APIResponse<BaseResponseInterface>, APIError, { ids: string[] }>(
    postUndoMultipleOrSingleDeleteNotificationService,
    mutateFn
  );
};
