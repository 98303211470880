/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-collapsible-if */
import { useAuth } from '@context/AuthContext';
import { useNotification } from '@context/NotificationContext';
import useSetSearchParams from '@core/hooks/useSetSearchParams';
import { localStorageService } from '@core/utils/localStorage';
import { setAsyncTimeout } from '@core/utils/setAsyncTimeout';
import {
  useGetAllNotification,
  usePostMultipleOrSingleDeleteNotification,
  usePostMultipleOrSingleRead,
  usePostUndoDeleteNotification
} from '@hooks/useGetNotificationService';
import {
  NotificationGroupInterface,
  NotificationHandleActionButtonInterface,
  ResponseGetNotification,
  ToastNotificationInterface
} from '@interface/NotificationInterface';
import { LanguageType } from '@interface/UserInterface';
import { dateFormatter } from '@utils/dateFormatter';
import { getInitialNameForFallbackAvatar } from '@utils/getInitialName';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths
} from 'date-fns';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import {
  BaseButton,
  BaseTabItemInterface,
  BaseTabs,
  BaseText,
  Checkbox,
  LoadingIndicator
} from 'uangcermat-web-toolkit-v2';

import EmptyNotificationComponent from './EmptyNotificationComponent';
import ListNotificationComponent from './ListNotificationComponent';
import ToastUndoNotificationComponent from './ToastUndoNotificationComponent';

const TODAY = new Date();

const initToast: ToastNotificationInterface = {
  visible: false,
  isError: false,
  message: 'Notification Deleted.',
  timeout: 1000
};

const NotificationComponent = ({
  onHidePopup,
  onClickNotification
}: {
  onHidePopup: () => void;
  onClickNotification: () => void;
}) => {
  const router = useRouter();
  const { t, i18n } = useTranslation(['common']);
  const { setSearchParams } = useSetSearchParams();
  const { userAuth, setActiveCompany } = useAuth();

  const { totalUnread, handleRefetchUnreadCount, setRedirectUrl, setActiveBranchId } =
    useNotification();
  const companyId = JSON.parse(localStorageService.getActiveCompany('active_company') || '{}').id;

  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [notifData, setNotifData] = useState<ResponseGetNotification[] | null>(null);
  const [notificationList, setNotificationList] = useState<NotificationGroupInterface[] | null>(
    null
  );

  const [isModify, setIsModify] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isShowBtnMore, setIsShowBtnMore] = useState<boolean>(true);
  const [isDeleteWarning, setIsDeleteWarning] = useState<boolean>(false);
  const [isPressShowMoreData, setIsPressShowMoreData] = useState<boolean>(false);

  const [isShowToastMessage, setIsShowToastMessage] =
    useState<ToastNotificationInterface>(initToast);

  const [paramsIds, setParamsIds] = useState<{ ids: string[] }>({ ids: [] });

  const [selectedTab, setSelectedTab] = useState<string>('all');

  const [currentPage, setCurrentPage] = useState<number>(1);

  const DATA_TABS_NOTIF: Array<BaseTabItemInterface> = [
    {
      id: 'all',
      text: t('common:all'),
      onClick: ({ id }) => handleOnClickTabs(id)
    },
    {
      id: 'unread',
      text: t('common:unread'),
      onClick: ({ id }) => handleOnClickTabs(id),
      isContainError: totalUnread ? true : false,
      errorCount: totalUnread
    }
  ];

  const handleOnClickTabs = (id: string) => {
    setSelectedTab(id);
    setCurrentPage(1);
    setNotifData(null);
    setAsyncTimeout(() => refetchNotificationData(), 100);
  };

  useEffect(() => {
    if (notifData === null && userAuth?.id && companyId) {
      refetchNotificationData();
    }
  }, []);

  useEffect(() => {
    if (selectedItem && selectedItem.length === 0) {
      setIsDeleteWarning(false);
    }
  }, [isDeleteWarning, selectedItem]);

  useEffect(() => {
    if (isShowToastMessage.visible) {
      setAsyncTimeout(() => {
        setIsShowToastMessage(initToast);
        setIsDeleteWarning(false);
      }, isShowToastMessage.timeout);
    }
  }, [isShowToastMessage.visible]);

  const { isFetching: isLoadingFetchNotificationData, refetch: refetchNotificationData } =
    useGetAllNotification({
      params: {
        subscriber: 'gajicermat',
        targetUserId: userAuth?.id ?? '',
        company_id: companyId,
        perPage: 6,
        page: currentPage,
        lang: i18n.language as LanguageType,
        status: selectedTab !== 'all' ? 10 : null
      },
      options: {
        cacheTime: 0,
        enabled: false,
        onSuccess: ({ data, meta }) => {
          setCurrentPage(meta.current_page);
          setIsShowBtnMore(currentPage < meta.last_page);
          if (data && isPressShowMoreData) {
            setIsPressShowMoreData(false);
            setNotifData(notifData ? notifData.concat(data) : data);
            setAsyncTimeout(
              () => handleGroupingNotificationList(notifData ? notifData.concat(data) : data),
              100
            );
          } else if (data) {
            setNotifData(data);
            setAsyncTimeout(() => handleGroupingNotificationList(data), 100);
          }
        }
      }
    });

  const { mutate: mutateMarkAsRead, isLoading: isLoadingMutateMarkAsRead } =
    usePostMultipleOrSingleRead({
      onSuccess: ({ error }) => {
        if (!error) {
          handleOnSuccessState();
        }
      }
    });

  const { mutate: mutateDeleteNotification, isLoading: isLoadingMutateDeleteNotification } =
    usePostMultipleOrSingleDeleteNotification({
      onSuccess: ({ error, message }) => {
        if (!error) {
          refetchNotificationData();
          handleRefetchUnreadCount();
          setCurrentPage(1);
          setIsModify(false);
          setIsShowToastMessage({
            visible: true,
            isError: false,
            message: t('common:notificationDeleted'),
            timeout: 5000
          });
        } else {
          setIsShowToastMessage({
            visible: true,
            isError: true,
            message: message,
            timeout: 5000
          });
        }
      }
    });

  const { mutate: mutateUndoDeleteNotification, isLoading: isLoadingMutateUndoDeleteNotification } =
    usePostUndoDeleteNotification({
      onSuccess: ({ error }) => {
        if (!error) {
          handleOnSuccessState();
        }
      }
    });

  const handleGroupingNotificationList = (data: ResponseGetNotification[]) => {
    const newListNotification: NotificationGroupInterface[] = [];
    data.map((item: ResponseGetNotification, index: number) => {
      const groupLabel =
        item.group === 'today' || item.group == '7_days' ? t('common:current') : t('common:older');
      if (index === 0) {
        newListNotification.push({
          index: index + 1,
          group: groupLabel,
          title: groupLabel as string,
          isModify,
          selectedItem,
          list: [
            {
              id: item.id,
              title: item.title,
              isModify,
              isNewData: item.status === 'SENT',
              isSelected: selectedItem.includes(item.id),
              description: item.content,
              time: getDiffDate({ date: new Date(item.timestamp) }),
              url: item.navigate ?? '',
              avatarUrl: item.avatar ?? '',
              avatarText: getInitialNameForFallbackAvatar({ name: item.title }),
              onClickItem: () => clickDetailItem(item.id, item.action),
              selectedItem,
              action: item.action
            }
          ]
        });
      } else {
        const groupIndex = newListNotification.findIndex(
          (datas: NotificationGroupInterface) => datas.group === groupLabel
        );
        if (groupIndex >= 0) {
          const currentList = newListNotification[groupIndex].list;
          currentList?.push({
            id: item.id,
            title: item.title,
            isModify,
            isNewData: item.status === 'SENT',
            isSelected: selectedItem.includes(item.id),
            description: item.content,
            time: getDiffDate({ date: new Date(item.timestamp) }),
            url: item.navigate ?? '',
            avatarUrl: item.avatar ?? '',
            avatarText: getInitialNameForFallbackAvatar({ name: item.title }),
            onClickItem: () => clickDetailItem(item.id, item.action),
            selectedItem,
            action: item.action
          });
        } else {
          newListNotification.push({
            index: index + 1,
            group: groupLabel,
            title: groupLabel as string,
            isModify,
            selectedItem: [],
            list: [
              {
                id: item.id,
                title: item.title,
                isModify,
                isNewData: item.status === 'SENT',
                isSelected: selectedItem.includes(item.id),
                description: item.content,
                time: getDiffDate({ date: new Date(item.timestamp) }),
                url: item.navigate ?? '',
                avatarUrl: item.avatar ?? '',
                avatarText: getInitialNameForFallbackAvatar({ name: item.title }),
                onClickItem: () => clickDetailItem(item.id, item.action),
                selectedItem,
                action: item.action
              }
            ]
          });
        }
      }
    });
    setNotificationList(newListNotification);
  };

  const clickDetailItem = useCallback(
    (id: string, url?: string) => {
      if (isModify) {
        if (selectedItem && selectedItem.length) {
          const newSelectedItem: string[] = [];
          const checkData = selectedItem.includes(id);
          if (checkData) {
            selectedItem.map((notificationId: string) => {
              if (notificationId !== id) {
                newSelectedItem.push(notificationId);
              }
            });
            setSelectedItem(newSelectedItem);
            setParamsIds({ ids: newSelectedItem });
            setIsSelectAll(false);
          } else {
            setSelectedItem([...selectedItem, id]);
            setParamsIds({ ids: [...selectedItem, id] });
          }
        } else {
          setSelectedItem([id]);
          setParamsIds({ ids: [id] });
        }
      } else {
        onClickNotification();
        mutateMarkAsRead({ ids: [id] });
        setAsyncTimeout(() => {
          refetchNotificationData();
          handleRefetchUnreadCount();
          setSelectedTab('all');
        }, 500);
        setCurrentPage(1);
        if (url && url !== undefined) {
          let newModule = '';
          let branchId = '';
          let companyId = '';
          let leaveId = '';
          const newUrl = url.split('/');
          if (newUrl[0]) {
            newModule = newUrl[0];
            if (newModule === 'leave') {
              const detailIds = newUrl[1].split('?');
              const branchIds = detailIds[1].split('&');
              companyId = branchIds[1].replace('cId=', '');
              branchId = branchIds[2].replace('lId=', '');
              leaveId = branchIds[0].replace('id=', '');
            }
          } else {
            newModule = newUrl[1];
            if (newModule === 'leave') {
              const detailIds = newUrl[2].split('?');
              const branchIds = detailIds[1].split('&');
              companyId = branchIds[1].replace('cId=', '');
              leaveId = branchIds[0].replace('id=', '');
              if (branchIds[2]) {
                branchId = branchIds[2].replace('lId=', '');
              }
            }
          }
          if (newModule === 'leave') {
            userAuth?.companies.map((company) => {
              if (company.clientId === companyId) {
                setActiveCompany(company);
              }
            });
            if (branchId) {
              setActiveBranchId(branchId);
              setSearchParams('lId', branchId);
            }
            setTimeout(() => {
              onHidePopup();
              const cleanedUrl = url.replace(/\/leave\/list\?.*/, '/leave/list');
              router.push(
                cleanedUrl.replace('list', `detail/${leaveId}?cId=${companyId}&lId=${branchId}`)
              );
            }, 500);
          } else {
            setActiveBranchId(branchId);
            setSearchParams('lId', branchId);
            setRedirectUrl('attendance.approve');
            setTimeout(() => {
              onHidePopup();
              router.push(url);
            }, 500);
          }
        }
      }
    },
    [isModify, router, selectedItem, setActiveCompany, userAuth?.companies]
  );

  const getDiffDate = useCallback(
    ({ date }: { date: Date }) => {
      let diffTextString = '';
      const diffMonths = differenceInMonths(TODAY, date);
      const diffDays = differenceInDays(TODAY, date);
      const diffHours = differenceInHours(TODAY, date);
      const diffMinutes = differenceInMinutes(TODAY, date);
      if (diffMonths <= 1) {
        if (!diffDays && !diffHours && diffMinutes <= 1) {
          diffTextString = t('common:notification.minuteAgoLabel');
        } else if (!diffDays && !diffHours && diffMinutes > 1) {
          diffTextString = `${diffMinutes} ${t('common:notification.minutesAgoLabel')}`;
        } else if (!diffDays && diffHours === 1) {
          diffTextString = t('common:notification.hourAgoLabel');
        } else if (!diffDays && diffHours > 1 && diffHours < 24) {
          diffTextString = `${diffHours} ${t('common:notification.hoursAgoLabel')}`;
        } else if (diffDays > 7) {
          diffTextString = dateFormatter(date, 'dd MMMM yyyy', {
            locale: i18n.language as LanguageType
          });
        } else if (diffDays === 1) {
          diffTextString = t('common:notification.dayAgoLabel');
        } else {
          diffTextString = `${diffDays} ${t('common:notification.daysAgoLabel')}`;
        }
      } else {
        diffTextString = `${diffMonths} ${t('common:notification.monthAgoLabel')}`;
      }
      return diffTextString;
    },
    [i18n.language, t]
  );

  const handlePressMoreBtn = async () => {
    setCurrentPage(currentPage + 1);
    setIsPressShowMoreData(true);
    setAsyncTimeout(() => refetchNotificationData(), 100);
  };

  const handleCheckBoxAll = useCallback(() => {
    setIsSelectAll((prev) => !prev);
    if (isModify) {
      if (!isSelectAll) {
        if (notifData && notifData.length) {
          const nSelectedData: string[] = [];
          notifData?.map((values: ResponseGetNotification) => nSelectedData.push(values.id));
          setSelectedItem(nSelectedData);
          setParamsIds({ ids: nSelectedData });
        } else {
          setSelectedItem([]);
        }
      } else {
        setSelectedItem([]);
      }
    }
  }, [isModify, isSelectAll, notifData]);

  const handleActionFromNotification = useCallback(
    (type: NotificationHandleActionButtonInterface | string) => {
      if (selectedItem && selectedItem.length) {
        switch (type) {
          case 'delete':
            mutateDeleteNotification(paramsIds);
            break;

          case 'undo':
            mutateUndoDeleteNotification(paramsIds);
            break;

          case 'markAsRead':
            mutateMarkAsRead(paramsIds);
            break;
        }
      }
    },
    [mutateUndoDeleteNotification, paramsIds, selectedItem]
  );

  const handleOnSuccessState = async () => {
    await setCurrentPage(1);
    await refetchNotificationData();
    await handleRefetchUnreadCount();
    await setSelectedItem([]);
    await setParamsIds({ ids: [] });

    if (isModify) {
      await setIsModify(false);
    }

    if (isDeleteWarning) {
      await setIsDeleteWarning(false);
    }

    if (isShowToastMessage.visible) {
      setIsShowToastMessage(initToast);
    }
  };

  const isLoadingState =
    !isLoadingMutateDeleteNotification &&
    !isLoadingMutateMarkAsRead &&
    !isLoadingFetchNotificationData &&
    !isLoadingMutateUndoDeleteNotification;

  return (
    <div className="bg-light-whiteSolid flex flex-col w-[360px] h-[514px] shadow-md rounded-lg">
      <div className="p-4">
        <BaseText
          label={t('common:notification.notification')}
          className="text-base font-semibold text-dark-blackCoral"
        />
      </div>
      <div className="flex flex-row items-center p-4 gap-x-2.5">
        {isModify && (
          <Checkbox
            key={'checkBoxAll'}
            checked={isSelectAll}
            variant="small"
            onChange={() => {
              handleCheckBoxAll();
            }}
          />
        )}
        <div className="flex flex-1">
          <BaseTabs activeItem={selectedTab} list={DATA_TABS_NOTIF} />
        </div>
        <a
          className="cursor-pointer"
          onClick={() => {
            setSelectedItem([]);
            setIsModify(!isModify);
            setIsSelectAll(false);
          }}
        >
          <BaseText
            label={!isModify ? t('common:button.edit') : t('common:button.cancel')}
            className="text-hyperlink-color-default hover:underline text-xs font-normal decoration-hyperlink-color-default"
          />
        </a>
      </div>

      {isModify && (
        <div
          className={twMerge(
            'flex flex-row p-4 bg-light-whiteSmoke gap-x-2.5',
            !isDeleteWarning ? 'justify-end' : 'justify-between'
          )}
        >
          {!isDeleteWarning ? (
            <>
              <a
                className={twMerge(
                  selectedItem && selectedItem.length ? 'cursor-pointer' : 'cursor-not-allowed'
                )}
                onClick={() => handleActionFromNotification('markAsRead')}
              >
                <BaseText
                  label={t('common:markAsRead')}
                  className="text-hyperlink-color-default hover:underline text-xs font-normal decoration-hyperlink-color-default"
                />
              </a>

              <a
                className={twMerge(
                  selectedItem && selectedItem.length ? 'cursor-pointer' : 'cursor-not-allowed'
                )}
                onClick={() => {
                  if (selectedItem && selectedItem.length) {
                    setIsDeleteWarning(true);
                  }
                }}
              >
                <BaseText
                  label={t('common:button.delete')}
                  className="text-hyperlink-color-default hover:underline text-xs font-normal decoration-hyperlink-color-default"
                />
              </a>
            </>
          ) : (
            <>
              <div className="min-w-[264px]">
                <BaseText
                  label={`${t('common:confirmDeleteNotif')} ${selectedItem.length} ${t('common:notification.notification').toLowerCase()}?`}
                  variant="small"
                  className="font-semibold text-xs"
                />
              </div>
              <div className="w-[54px] flex flex-row">
                <a className="cursor-pointer mr-4" onClick={() => setIsDeleteWarning(false)}>
                  <BaseText
                    label={t('common:no')}
                    className="text-hyperlink-color-default hover:underline text-xs font-normal decoration-hyperlink-color-default"
                  />
                </a>
                <a
                  className="cursor-pointer"
                  onClick={() => handleActionFromNotification('delete')}
                >
                  <BaseText
                    label={t('common:yes')}
                    className="text-hyperlink-color-default hover:underline text-xs font-normal decoration-hyperlink-color-default"
                  />
                </a>
              </div>
            </>
          )}
        </div>
      )}

      {isLoadingState ? (
        notifData?.length ? (
          <>
            <div className="flex flex-1 flex-col overflow-auto">
              <ListNotificationComponent
                isModify={isModify}
                notificationData={notificationList}
                onClickListDetail={(ids, actions) => clickDetailItem(ids, actions)}
                notificationSelected={selectedItem}
              />

              <ToastUndoNotificationComponent
                isShowToast={isShowToastMessage.visible}
                toastMsg={isShowToastMessage.message || t('common:notificationDeleted')}
                isError={isShowToastMessage.isError}
                handleActionButton={(values) => handleActionFromNotification(values)}
                isShowBtnMore={isShowBtnMore}
              />
            </div>

            {isShowBtnMore && (
              <div className="w-full p-2 bg-light-whiteSmoke flex items-center justify-center">
                <BaseButton onClick={() => handlePressMoreBtn()} label={t('common:showMore')} />
              </div>
            )}
          </>
        ) : (
          <EmptyNotificationComponent />
        )
      ) : (
        <div className="flex flex-1 flex-col overflow-auto items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

export default NotificationComponent;
