import Cookies from 'js-cookie';

interface GetCookieInterface {
  name:
    | 'access_token'
    | 'refresh_token'
    | 'authInfoLocation'
    | 'authInfoCompany'
    | 'payrollHistoryColumnUnchecked'
    | 'payrollInProcessColumnUnchecked'
    | 'employeeDirectoryColumnUnchecked'
    | 'onboardingColumnUnchecked'
    | 'wsColumnUnchecked'
    | 'employeeLeaveUnchecked'
    | 'addEmployeeAllowance'
    | 'removeEmployeeSelected'
    | 'onBoardingOpenedTableHeader'
    | 'directoryShowHide'
    | 'showHideWSTable'
    | 'companies';
}

interface SetCookieInterface extends GetCookieInterface {
  value: string;
  options?: Cookies.CookieAttributes;
}
export const appCookies = () => {
  const setCookie = async ({ name, value, options }: SetCookieInterface) =>
    Cookies.set(name, value, options);
  const removeCookie = async ({ name }: GetCookieInterface) => Cookies.remove(name);
  const getCookie = async ({ name }: GetCookieInterface) => Cookies.get(name);

  const removeAllCookies = async () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    Cookies.remove('authInfoLocation');
    Cookies.remove('authInfoCompany');
    Cookies.remove('payrollHistoryColumnUnchecked');
    Cookies.remove('payrollInProcessColumnUnchecked');
    Cookies.remove('employeeDirectoryColumnUnchecked');
    Cookies.remove('onboardingColumnUnchecked');
    Cookies.remove('wsColumnUnchecked');
    Cookies.remove('employeeLeaveUnchecked');
    Cookies.remove('addEmployeeAllowance');
    Cookies.remove('removeEmployeeSelected');
    Cookies.remove('directoryShowHide');
    Cookies.remove('showHideWSTable');
    Cookies.remove('onBoardingOpenedTableHeader');
    Cookies.remove('companies');
  };

  return {
    setCookie,
    removeCookie,
    getCookie,
    removeAllCookies
  };
};
