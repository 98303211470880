import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface LeaveContextInterface {
  isShowToast: {
    isVisible: boolean;
    msg: string;
    duration: number;
    category: string;
    containerStyles?: string;
  };
  setIsShowToast: (values: {
    isVisible: boolean;
    msg: string;
    duration: number;
    category: string;
    containerStyles?: string;
  }) => void;
  statusName: string;
  setStatusName: Dispatch<SetStateAction<string>>;
}

export const LeaveContext = createContext<LeaveContextInterface>({
  isShowToast: {
    isVisible: false,
    msg: '',
    duration: 5000,
    category: ''
  },
  setIsShowToast: () => undefined,
  statusName: '',
  setStatusName: () => undefined
});

export const LeaveProvider = ({ children }: { children: ReactNode }) => {
  const [isShowToast, setIsShowToast] = useState({
    isVisible: false,
    msg: '',
    duration: 5000,
    category: ''
  });

  const [statusName, setStatusName] = useState<string>('');

  return (
    <LeaveContext.Provider value={{ isShowToast, setIsShowToast, statusName, setStatusName }}>
      {children}
    </LeaveContext.Provider>
  );
};

export const useLeaveContext = () => useContext(LeaveContext);
