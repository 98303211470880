import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import {
  ChangeLanguageParamsInterface,
  ErrorChangeLanguageInterface,
  ResponseChangeLanguageInterface
} from '@interface/UserInterface';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

export const postChangeLanguage = async (
  body: ChangeLanguageParamsInterface
): Promise<APIResponse<ResponseChangeLanguageInterface>> => {
  const { data } = await axiosAuthInstance.post('/users/language', {
    ...body
  });
  return data;
};

export default function usePostChangeLanguage(
  options: UseMutationOptions<
    APIResponse<ResponseChangeLanguageInterface>,
    AxiosError<APIResponse<ErrorChangeLanguageInterface>>,
    ChangeLanguageParamsInterface
  >
) {
  return useMutation((body: ChangeLanguageParamsInterface) => postChangeLanguage(body), options);
}
