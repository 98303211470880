/* eslint-disable sonarjs/cognitive-complexity */
import { getUserLogout } from '@api/GET_Logout';
import { getUserMe } from '@api/GET_Me';
import { postUserLogin } from '@api/POST_Login';
import { useI18n } from '@core/hooks/useI18n';
import { appCookies } from '@core/utils/appCookies';
import { isAxiosError } from '@core/utils/errorUtils';
import { localStorageService } from '@core/utils/localStorage';
import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import useGetCompaniesLocation from '@hooks/useGetCompaniesLocation';
import * as Sentry from '@sentry/nextjs';
import { epochFormatter } from '@utils/epochTimeFormatter';
import updateSlug from '@utils/updateSlug';
import { t } from 'i18next';
import {
  BranchInterface,
  BranchLocationInterface,
  DataMeInterface,
  LanguageType,
  PostBodyLoginInterface,
  UserCompaniesInterface
} from 'interface/UserInterface';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { LoadingIndicatorBox, showToast } from 'uangcermat-web-toolkit-v2';

interface AuthContextInterface {
  listBranch: Array<BranchInterface> | [];
  isAuthenticated: boolean;
  isLoading: boolean;
  userAuth: DataMeInterface | null;
  errorMessage: string | null;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  login: (value: PostBodyLoginInterface) => void;
  logout: () => void;
  refetchMe: () => void;
  setUserAuth: (value: DataMeInterface) => void;
  setErrorMessage: (message: null | string) => void;
  activeCompany: UserCompaniesInterface | undefined;
  setActiveCompany: (company: UserCompaniesInterface | undefined) => void;
  activeBranch: BranchInterface | undefined;
  setActiveBranch: (branch: BranchInterface | undefined) => void;
  redirectAfterLogin: string | null;
}

export const AuthContext = createContext<AuthContextInterface>({
  listBranch: [],
  isAuthenticated: false,
  isLoading: true,
  userAuth: null,
  errorMessage: null,
  setIsLoading: () => undefined,
  login: () => undefined,
  logout: () => undefined,
  refetchMe: () => undefined,
  setUserAuth: () => undefined,
  setErrorMessage: () => undefined,
  activeCompany: undefined,
  setActiveCompany: () => undefined,
  activeBranch: undefined,
  setActiveBranch: () => undefined,
  redirectAfterLogin: null
});

const isRedirectedToDashboard = (pathname: string): boolean => {
  return ['/', '/login', '/reset/password', 'reset/pin', 'change/email'].includes(pathname);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userAuth, setUserAuth] = useState<DataMeInterface | null>(null);
  const [activeCompany, setActiveCompany] = useState<UserCompaniesInterface>();
  const [activeBranch, setActiveBranch] = useState<BranchInterface>();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listBranch, setListBranch] = useState<Array<BranchInterface>>([]);

  const { setCookie, getCookie, removeAllCookies } = appCookies();
  const router = useRouter();

  const { changeLanguage } = useI18n();

  const currentUrl = useRef<string | null>(null);
  const redirecAfterLogin = useRef<string | null>(null);
  const branchId = useRef<string | null>(null);

  const { refetch: refetchUserData } = useQuery<APIResponse<DataMeInterface>>('/me', getUserMe, {
    enabled: false,
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onSuccess: async ({ data, error }) => {
      setIsLoading(false);

      if (!error) {
        currentUrl.current = window.location.href;

        if (localStorageService.getDirectUrl()) {
          currentUrl.current = localStorageService.getDirectUrl();
          localStorageService.setDirectUrl('');
        } else {
          currentUrl.current = window.location.href;
        }

        const url = new URL(currentUrl.current!);

        redirecAfterLogin.current = url.pathname + url.search;

        //check if direct url have a cId
        const cId = url.searchParams.get('cId');
        const lId = url.searchParams.get('lId');

        //save companies to cookie
        setCookie({
          name: 'companies',
          value: JSON.stringify(data?.companies.map((e) => e.id))
        });

        if (cId) {
          const company = data?.companies.find((company) => company.id === cId);
          //if company exist
          if (company) {
            setCookie({
              name: 'authInfoCompany',
              value: JSON.stringify(company)
            });

            //check apa ada branch id
            if (lId) {
              branchId.current = lId;
            }
          } else {
            //add to sentry
            Sentry.captureEvent({
              message: 'Company not found in user companies',
              level: 'error', // Level log (fatal, error, warning, info, debug)
              extra: {
                userEmail: data?.email,
                fullUrlBeforRedirect: window.location.href,
                companies: data?.companies
              }
            });
            //redirect to company page
            router.push('/company');
            //show toast company not found
            showToast({
              type: 'error',
              title: `${t('common:dccessDenied')}!`,
              description: t('common:youDoNotHaveAccessToThisCompany'),
              duration: 5000
            });
          }
          //set active company to cookie
        }

        setRedirectAfterLogin(data?.redirectAfterLogin);

        const activeCompanyLocalStorage: UserCompaniesInterface =
          typeof localStorageService.getActiveCompany('active_company') === 'string' &&
          JSON.parse(localStorageService.getActiveCompany('active_company') || '');

        const activeCompany = await getCookie({ name: 'authInfoCompany' });

        setUserAuth(data);
        setActiveCompany(activeCompanyLocalStorage);
        changeLanguage(data?.lang);

        if (isRedirectedToDashboard(router.pathname)) {
          if (activeCompanyLocalStorage) {
            if (data?.permissions.includes('payroll.employee.list')) {
              router.push(`/employee/directory?cId=${activeCompanyLocalStorage.id}`);
            } else if (data?.permissions.includes('payroll.leave.list')) {
              router.push(`/leave/request?cId=${activeCompanyLocalStorage.id}`);
            }
          } else {
            if (activeCompany) {
              const pharsCompany = JSON.parse(activeCompany);
              setActiveCompany(pharsCompany);

              localStorageService.setActiveCompany({
                key: 'active_company',
                value: JSON.stringify(pharsCompany)
              });

              if (redirecAfterLogin.current) {
                router.push(`${updateSlug(redirecAfterLogin.current)}`);
              } else {
                router.push(`${updateSlug(data.redirectAfterLogin)}?cId=${pharsCompany?.id}`);
              }
            } else if (data?.companies.length === 1) {
              setActiveCompany(data?.companies[0]);

              localStorageService.setActiveCompany({
                key: 'active_company',
                value: JSON.stringify(data?.companies[0])
              });

              router.push(`${updateSlug(data.redirectAfterLogin)}?cId=${data?.companies[0]?.id}`);
            } else {
              if (activeCompany) {
                const pharsCompany = JSON.parse(activeCompany);
                setActiveCompany(pharsCompany);
                localStorageService.setActiveCompany({
                  key: 'active_company',
                  value: JSON.stringify(pharsCompany)
                });
                router.push(`${updateSlug(data.redirectAfterLogin)}?cId=${pharsCompany?.id}`);
              } else if (data?.companies.length === 1) {
                setActiveCompany(data?.companies[0]);
                localStorageService.setActiveCompany({
                  key: 'active_company',
                  value: JSON.stringify(data?.companies[0])
                });
                router.push(`${updateSlug(data.redirectAfterLogin)}?cId=${data?.companies[0]?.id}`);
              } else {
                router.push('/company');
              }
            }
          }
        } else {
          if (activeCompany) {
            const pharsCompany = JSON.parse(activeCompany);
            setActiveCompany(pharsCompany);
            localStorageService.setActiveCompany({
              key: 'active_company',
              value: JSON.stringify(pharsCompany)
            });
          }
        }
      }
    }
  });

  const { data: logoutData, refetch: refetchLogout } = useQuery('/logout', getUserLogout, {
    enabled: false,
    onSuccess: () => {
      setIsLoading(false);
      try {
        if (!logoutData?.error) {
          localStorage.removeItem('reportLeaveOpenedTableHeader');
          setUserAuth(null);
          setActiveCompany(undefined);
          removeAllCookies();
          localStorageService.clearActiveCompany('active_company');
          sessionStorage.setItem('lastPathname', '');
          router.push('/login');
        } else {
          alert(logoutData.message);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (isAxiosError(error)) {
          alert(error?.message);
        }
      }
    }
  });

  const { mutateAsync: mutateAsyncLogin } = useMutation('/login', postUserLogin, {
    onSuccess: () => {
      setIsLoading(false);
    }
  });

  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('access_token');
    if (!userAuth && token) {
      setIsLoading(true);
      await refetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userAuth]);

  const { refetch: refetchBranch } = useGetCompaniesLocation({
    companyId: activeCompany?.id,
    params: { perPage: 9999, sortBy: 'created_at', sort: 'asc' },
    options: {
      enabled: !!activeCompany?.id,
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        if (!response.error && response.data?.length) {
          const listBranch = response.data.map((branchLocation: BranchLocationInterface) => {
            return {
              id: branchLocation.id,
              clientId: branchLocation.id,
              name: branchLocation.name
            };
          });
          setListBranch(listBranch);
          if (branchId.current) {
            const branch = listBranch.find((branch) => branch.id === branchId.current);
            if (branch) {
              setActiveBranch(branch);
              await setCookie({
                name: 'authInfoLocation',
                value: JSON.stringify(branch)
              });
            }
          }
        }
      }
    }
  });

  useEffect(() => {
    if (activeCompany) {
      refetchBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.id]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('access_token');
      const refreshToken = Cookies.get('refresh_token');
      const { type } = router.query;
      if (token) {
        if (
          !userAuth &&
          router.pathname !== '/login' &&
          type?.toString() !== 'pin' &&
          type?.toString() !== 'password'
        ) {
          fetchUserProfile();
        } else {
          setIsLoading(false);
        }
      } else {
        if (refreshToken) {
          if (type !== 'autologin') {
            syncRefreshToken().finally(() => {
              fetchUserProfile();
            });
          }
        } else {
          setIsLoading(false);
          if (
            router.pathname !== '/login' &&
            router.pathname !== '/reset/password' &&
            router.pathname !== '/reset/pin' &&
            router.pathname !== '/change/email'
          ) {
            if (!isRedirectedToDashboard(window.location.pathname)) {
              localStorageService.setDirectUrl(window.location.href);
            }

            localStorageService.clearActiveCompany('active_company');
            router.push('/login');
          }
        }
      }
    }
    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    setIsLoading(true);
    refetchLogout();
  };

  const handleRefetchMe = () => {
    const token = Cookies.get('access_token');
    if (token) {
      setIsLoading(true);
      refetchUserData();
    }
  };

  const login = async ({
    email,
    password,
    lang
  }: {
    email: string;
    password: string;
    lang: LanguageType;
  }) => {
    try {
      setIsLoading(true);
      const { error, access_token, refresh_token, expire_token, message } = await mutateAsyncLogin({
        email,
        password,
        lang
      });
      if (!error && access_token && !userAuth) {
        setCookie({
          name: 'access_token',
          value: access_token,
          options: {
            expires: expire_token ? epochFormatter(expire_token) : undefined
          }
        });
        if (refresh_token) {
          setCookie({
            name: 'refresh_token',
            value: refresh_token
          });
        }
        localStorageService.setToken({
          key: 'expire_token',
          value: expire_token?.toString()
        });
        fetchUserProfile();
        sessionStorage.setItem('lastPathname', '');
      }
      if (error && message) {
        setErrorMessage(message);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        const errMsg = error.response?.data.message;
        setErrorMessage(errMsg?.email || errMsg?.password || error.message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        listBranch,
        isAuthenticated: !!userAuth,
        userAuth,
        setIsLoading,
        login,
        isLoading,
        logout: handleLogout,
        refetchMe: handleRefetchMe,
        errorMessage,
        setErrorMessage,
        setUserAuth,
        activeCompany,
        setActiveCompany,
        activeBranch,
        setActiveBranch,
        redirectAfterLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line react/prop-types
export const ProtectRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  return (
    <>
      {(isLoading ||
        (!isAuthenticated &&
          router.pathname !== '/login' &&
          router.pathname !== '/reset/password' &&
          router.pathname !== '/reset/pin' &&
          router.pathname !== '/change/email')) && (
        <LoadingIndicatorBox color={process.env.LOADING_INDICATOR_COLOR} backdrop />
      )}
      {children}
    </>
  );
};
