/* eslint-disable sonarjs/cognitive-complexity */
import images from '@assets/images.json';
import { useAuth } from '@context/AuthContext';
import { useLeaveContext } from '@context/LeaveContext';
import { useNotification } from '@context/NotificationContext';
import { useI18n } from '@core/hooks/useI18n';
import { useNavigationConfig } from '@core/hooks/useNavigationConfig';
import useSetSearchParams from '@core/hooks/useSetSearchParams';
import { appCookies } from '@core/utils/appCookies';
import { getGuardedNavigation } from '@core/utils/getGuardedNavigation';
import { localStorageService } from '@core/utils/localStorage';
import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import usePostChangeLanguage from '@hooks/usePostChangeLanguage';
import { LanguageType } from '@interface/UserInterface';
import { getInitialCompanyNameForFallbackAvatar } from '@utils/getInitialCompanyNameForFallbackAvatar';
import { getInitialNameForFallbackAvatar } from '@utils/getInitialName';
import updateSlug from '@utils/updateSlug';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar } from 'uangcermat-web-toolkit-v2';
import { SidebarMenuDefaultInterface } from 'uangcermat-web-toolkit-v2/build/src/blocks/Sidebar/Sidebar.type';

import NotificationComponent from './components/NotificationComponent';

interface SidebarLayoutInterface {
  children: React.ReactNode;
}

const sidebarActiveParentIdMapper: Record<string, string> = {
  '/payroll': 'payrollCycle',
  '/payroll/history': 'payrollCycle',
  '/payroll/prefund': 'payrollCycle',
  '/employee': 'employee',
  '/employee/directory': 'employee',
  '/employee/onboarding': 'employee',
  '/leave': 'leave',
  '/leave/request': 'leave',
  '/leave/add': 'leave',
  '/leave/history': 'leave',
  '/attendance': 'attendance',
  '/attendance/submission': 'attendance',
  '/attendance/approval': 'attendance',
  '/attendance/import': 'attendance',
  '/attendance/history': 'attendance',
  '/attendance/payroll-feed': 'attendance',
  '/work-schedule': 'workSchedule',
  '/payroll-component/allowance': 'payrollComponent',
  '/payroll-component/deduction': 'payrollComponent',
  '/settings': 'setting',
  '/settings/company': 'settings',
  '/settings/notification': 'settings',
  '/settings/leave': 'settings',
  '/settings/attendance': 'settings',
  '/report': 'report',
  '/report/daily-attendance': 'report',
  '/report/attendance': 'report',
  '/report/pph-21': 'report',
  '/report/bpjs': 'report',
  '/report/employment-status': 'report',
  '/report/leave': 'report',
  '/settings/approval': 'settings',
  '/settings/holiday': 'settings',
  '/settings/overtime': 'settings',
  '/settings/branch': 'settings'
};

const sidebarActiveIdMapper: Record<string, string> = {
  '/payroll/detail': '/payroll',
  '/work-schedule/detail': '/work-schedule',
  '/work-schedule/add': '/work-schedule',
  '/leave/detail': '/leave/request',
  '/leave/detail/history': '/leave/history'
};

const SidebarLayout = ({ children }: SidebarLayoutInterface) => {
  const { t } = useTranslation(['menu']);
  const router = useRouter();
  const companies = JSON.parse(Cookies.get('companies') || '{}');

  const { isHistory } = router.query;
  const [activeId, setActiveId] = useState<string>('');
  const [activeParent, setActiveParent] = useState<string>('');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isRefreshNotification, setIsRefreshNotification] = useState<boolean>(false);
  const { changeLanguage } = useI18n();
  const {
    logout,
    userAuth,
    activeCompany,
    activeBranch,
    setIsLoading,
    refetchMe,
    setActiveCompany,
    setActiveBranch,
    redirectAfterLogin
  } = useAuth();

  const [key, setKey] = useState(0);
  const [isTrigger, setIsTrigger] = useState<boolean>(false);

  const lastPathname = sessionStorage.getItem('lastPathname');

  const { totalUnread, redirectUrl } = useNotification();
  const { navigationConfig } = useNavigationConfig();

  const { setIsShowToast } = useLeaveContext();

  useEffect(() => {
    if (router.asPath.includes('/leave/request') && router.asPath !== '/') {
      sessionStorage.setItem('directTo', router.asPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentPath = router.asPath.split('?')[0];
    const regex = /^\/[^/]+\/[^/]+(?:$|\/)/;
    const match = currentPath.match(regex);

    if (match) {
      let activeId = match[0].replace(/\/$/, '');
      const queryString = Object.entries(router.query)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map(([key, value]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      if (isHistory === 'true' && activeId === '/leave/detail') {
        activeId = activeId + '/history';
      } else if (activeId === '/leave/request' && router.query.id) {
        router.replace(`/leave/detail/${router.query.id}?${queryString}`);
        refetchMe();
      }

      if (activeId === '/leave/add') {
        activeId = isHistory === 'true' ? '/leave/history' : '/leave/request';
      }

      setActiveId(sidebarActiveIdMapper[activeId] ?? activeId);
      setActiveParent(sidebarActiveParentIdMapper[sidebarActiveIdMapper[activeId] ?? activeId]);
      if (isTrigger) {
        setKey(key + 1);
        setIsTrigger(false);
      } else {
        setKey(1);
      }
    } else {
      if ((!activeId || activeId == '/') && !lastPathname && !redirectUrl) {
        if (router.asPath.split('?')[0] !== '/payroll') {
          setActiveId(router.asPath.split('?')[0]);
          setActiveParent(sidebarActiveParentIdMapper[router.asPath.split('?')[0]]);
        } else {
          setActiveId('/payroll');
          setActiveParent(sidebarActiveParentIdMapper['/payroll']);
        }
      }
      if (redirectUrl) {
        setActiveId(redirectUrl);
        setActiveParent(sidebarActiveParentIdMapper[redirectUrl]);
        setKey(key + 1);
      }
      if (lastPathname && lastPathname !== activeId) {
        setActiveId(lastPathname);
        setActiveParent(sidebarActiveParentIdMapper[lastPathname]);
        setKey(key + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl, lastPathname, activeId, key, router, isHistory]);

  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';
  const selected = currentLanguage == 'en' ? 'id' : 'en';
  const { mutate: changeServerResponseLanguage } = usePostChangeLanguage({
    onSuccess: async ({ error }) => {
      if (!error) {
        await syncRefreshToken();
        await changeLanguage(selected);
        setIsLoading(false);
        window.history.replaceState(window.history.state, '', '');
      }
    }
  });

  const handleChangeLanguange = async () => {
    localStorage.removeItem('onBoardingOpenedTableHeader');
    setIsLoading(true);
    changeServerResponseLanguage({ lang: selected });
  };

  const { removeCookie } = appCookies();
  const { removeQueryParams } = useSetSearchParams();

  const handleSwitchCompany = async () => {
    setActiveCompany(undefined);
    setActiveBranch(undefined);
    removeCookie({ name: 'authInfoCompany' });
    localStorageService.clearActiveCompany('active_company');
    removeQueryParams('lId');

    sessionStorage.setItem('directTo', '');
    sessionStorage.setItem('lastPathname', '');

    await router.replace('/company');
  };

  const handleClickLogoOnSideBar = () => {
    if (redirectAfterLogin) {
      setActiveId(redirectAfterLogin);
      setActiveParent(sidebarActiveParentIdMapper[redirectAfterLogin]);
      router.push(
        `${updateSlug(redirectAfterLogin)}?cId=${activeCompany?.id}&lId=${activeBranch?.id}`
      );
    } else {
      setActiveId('/employee/directory');
      setActiveParent(sidebarActiveParentIdMapper['/employee/directory']);
      router.push(`/employee/directory?cId=${activeCompany?.id}&lId=${activeBranch?.id}`);
    }
  };

  const handleClickNavigation = (navLink: SidebarMenuDefaultInterface) => {
    setIsShowToast({
      isVisible: false,
      msg: '',
      duration: 5000,
      category: ''
    });
    sessionStorage.setItem('lastPathname', navLink.navLink?.split('?')[0] as string);
    sessionStorage.setItem('directTo', '');
    router.push(`${navLink.navLink as string}?cId=${activeCompany?.id}&lId=${activeBranch?.id}`);
  };

  return (
    <div className="flex flex-row w-screen h-screen bg-gray-100" key={key}>
      <Sidebar
        activeId={activeId}
        activeParent={activeParent}
        isCollapsed={isCollapsed}
        data={getGuardedNavigation({
          navConfig: navigationConfig,
          userPermission: userAuth?.permissions ?? []
        })}
        onClickNav={handleClickNavigation}
        onClickCollapsible={() => setIsCollapsed(!isCollapsed)}
        containerStyles={`${isCollapsed ? 'w-14' : 'w-[220px] md:w-[274px]'} min-h-screen`}
        itemMenuContainerStyles="overflow-y-auto scrollbar-none"
      >
        <Sidebar.Header
          isCollapsed={isCollapsed}
          onClickHeaderSidebar={handleClickLogoOnSideBar}
          appLogoOpened={images.sidebar.opened.url}
          appLogoClosed={images.sidebar.closed.url}
        />
        {isRefreshNotification ? (
          <></>
        ) : (
          <Sidebar.Popover
            isCollapsed={isCollapsed}
            label={t('common:notification.notification')}
            icon="notificationFill"
            notificationCount={totalUnread}
            offsetY={50}
            iconTriangleStyles="fill-light-whiteSolid"
            position="right-end"
          >
            <Sidebar.PopoverPanel>
              <NotificationComponent
                onClickNotification={() => setIsTrigger(!isTrigger)}
                onHidePopup={() => {
                  setIsRefreshNotification(true);
                  setTimeout(() => {
                    setIsRefreshNotification(false);
                  }, 1);
                }}
              />
            </Sidebar.PopoverPanel>
          </Sidebar.Popover>
        )}

        <Sidebar.UserProfile
          titleAtPanel={t('menu:profile.profileMenu')}
          manageAccountLabelAtPanel={t('menu:profile.myProfile')}
          containerStyles={classNames(
            'z-30',
            router.asPath.split('?')[0] === '/profile'
              ? 'bg-sidebar-profile-background-active text-sidebar-profile-text-active'
              : ''
          )}
          manageAccountLabelAtPanelStyles="py-2"
          panelContainerStyles="h-[228px] min-w-[240px]"
          isCollapsed={isCollapsed}
          userLabel={userAuth ? userAuth.firstName : ''}
          userLabelAtPanelStyles="font-semibold"
          userEmailAtPanelStyles="mt-1"
          userAvatarAtPanel={userAuth?.profilePicture ?? ''}
          userAvatar={userAuth?.profilePicture ?? ''}
          userAvatarAtPanelStyles="flex items-center justify-center rounded w-[32px] h-[32px] max-w-none min-w-[32px]"
          userAvatarFallback={getInitialNameForFallbackAvatar({
            name: userAuth ? `${userAuth?.firstName} ${userAuth?.lastName}` : '',
            length: 1
          })}
          userLabelAtPanel={userAuth ? `${userAuth.firstName} ${userAuth.lastName}` : ''}
          userAvatarFallbackAtPanel={getInitialNameForFallbackAvatar({
            name: userAuth ? `${userAuth?.firstName} ${userAuth?.lastName}` : '',
            length: 1
          })}
          translationLabelAtPanel={t('menu:profile.translatePage')}
          logoutLabelAtPanel={t('menu:profile.logout')}
          userEmailAtPanel={userAuth ? userAuth.email : ''}
          onClickManageAccount={() => router.push('/profile')}
          onClickTranslation={() => handleChangeLanguange()}
          onClickLogout={() => logout()}
          position="right"
          offsetY={companies.length > 1 ? 6 : -40}
        />
        <Sidebar.Footer
          appName="© PeopleNote"
          appVersion="Version 1.13.0"
          buttonChangeCompanyLabel={t('common:button.companyList')}
          companyName={activeCompany ? activeCompany.name : ''}
          companyLogo={activeCompany ? activeCompany.logo : ''}
          companyLogoFallback={
            activeCompany
              ? getInitialCompanyNameForFallbackAvatar({ name: activeCompany.name })
              : ''
          }
          isCollapsed={isCollapsed}
          isShowCompanyProfile
          isShowButtonChangeCompany={companies.length > 1}
          buttonChangeCompanyOnClick={handleSwitchCompany}
          buttonChangeCompanyStyles={isCollapsed ? undefined : 'h-[30px]'}
        />
      </Sidebar>

      <React.Fragment key={currentLanguage}>{children}</React.Fragment>
    </div>
  );
};

export default SidebarLayout;
