export const getInitialNameForFallbackAvatar = ({
  name,
  length
}: {
  name: string;
  length?: number;
}) => {
  let initial = '';
  name
    ?.trim()
    .split(' ')
    .forEach((name) => (initial += name.substring(0, 1)));
  if (initial.length > (length ?? 2)) {
    initial = initial.substring(0, length ?? 2);
  }

  return initial.substring(0, length ?? 2).toUpperCase();
};
