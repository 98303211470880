import { axiosCooperativeServiceInstance } from '@core/axios/axios.cooperativeServiceConfig';
import { BranchLocationInterface } from 'interface/UserInterface';
import { useQuery, UseQueryOptions } from 'react-query';

interface ParamsGetCompaniesLocation {
  perPage: number;
  sortBy?: string;
  sort?: string;
  page?: number;
  name?: string;
}

export const getCompaniesLocation = async ({
  companyId,
  params
}: {
  companyId: string | undefined;
  params: ParamsGetCompaniesLocation;
}): Promise<APIResponse<Array<BranchLocationInterface>>> => {
  const { data } = await axiosCooperativeServiceInstance.get(
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    `/companies/location/${companyId}`,
    {
      params
    }
  );
  return data;
};

const useGetCompaniesLocation = ({
  companyId,
  params,
  options
}: {
  companyId: string | undefined;
  params: ParamsGetCompaniesLocation;
  options?: UseQueryOptions<APIResponse<Array<BranchLocationInterface>>>;
}) => {
  return useQuery<APIResponse<Array<BranchLocationInterface>>>(
    ['useGetCompaniesLocation'],
    () => getCompaniesLocation({ companyId, params }),
    options
  );
};

export default useGetCompaniesLocation;
