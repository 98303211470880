export { default as BahasaAttendanceSubmission } from './attendanceSubmission.json';
export { default as BahasaChangeEmail } from './changeEmail.json';
export { default as BahasaCheckInOut } from './checkInOut.json';
export { default as BahasaCommon } from './common.json';
export { default as BahasaCooperative } from './cooperative.json';
export { default as BahasaDailyAttendance } from './dailyAttendance.json';
export { default as BahasaDirectory } from './directory.json';
export { default as BahasaEmployee } from './employee.json';
export { default as BahasaEmployeeLeave } from './employeeLeave.json';
export { default as BahasaEmploymentStatus } from './employmentStatus.json';
export { default as BahasaLeaveHistory } from './leaveHistory.json';
export { default as BahasaLoginPage } from './login.json';
export { default as BahasaMember } from './member.json';
export { default as BahasaMemberDetail } from './memberDetail.json';
export { default as BahasaMenu } from './menu.json';
export { default as BahasaOnboarding } from './onboarding.json';
export { default as BahasaPaycomponent } from './paycomponent.json';
export { default as BahasaPayroll } from './payroll.json';
export { default as BahasaPayrollEmployee } from './payrollEmployee.json';
export { default as BahasaEditProfile } from './profile.json';
export { default as BahasaReportBpjs } from './reportBpjs.json';
export { default as BahasaReportPph21 } from './reportPph21.json';
export { default as BahasaResetPassword } from './resetPassword.json';
export { default as BahasaResetPin } from './resetPin.json';
export { default as BahasaSettingApproval } from './settingApproval.json';
export { default as BahasaSettingAttendance } from './settingAttendance.json';
export { default as BahasaSettingsBranch } from './settingBranch.json';
export { default as BahasaSettingCompany } from './settingCompany.json';
export { default as BahasaSettingHoliday } from './settingHoliday.json';
export { default as BahasaSettingLeave } from './settingLeave.json';
export { default as BahasaSettingNotification } from './settingNotification.json';
export { default as BahasaSettingOvertime } from './settingOvertime.json';
export { default as BahasaWorkSchedule } from './workSchedule.json';
