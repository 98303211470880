import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

export const axiosBaseConfigOptions: AxiosRequestConfig = {
  timeout: 30000,
  headers: {
    'x-platform': process.env.APP_PLATFORM
  }
};

export const axiosInterceptorRequest = async (headerConfig: AxiosRequestConfig) => {
  const access_token = Cookies.get('access_token') ?? (await localStorage.getItem('access_token'));
  headerConfig.headers.Authorization = `Bearer ${access_token}`;

  return headerConfig;
};

export const axiosInterceptorResponseError = async (error: AxiosError) => {
  const status = error.response ? error.response.status : null;
  const errorConfig = error.config;

  if (status === 406) {
    syncRefreshToken().then(({ access_token, error }) => {
      if (!error && access_token && errorConfig.headers) {
        errorConfig.headers.Authorization = `Bearer ${access_token}`;
        return instance.request(errorConfig);
      }
    });
  }

  return Promise.reject(error);
};

export const instance = Axios.create({
  baseURL: process.env.APP_AUTH_SERVICE_URL,
  ...axiosBaseConfigOptions
});

instance.interceptors.request.use(axiosInterceptorRequest);

instance.interceptors.response.use((response) => response, axiosInterceptorResponseError);
