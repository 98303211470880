/* eslint-disable sonarjs/no-duplicate-string */
import { useTranslation } from 'react-i18next';
import { SidebarMenuInterface } from 'uangcermat-web-toolkit-v2';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<SidebarMenuInterface> = [
    {
      id: 'employee',
      icon: 'usergroup',
      title: t('menu:employees.employees'),
      permission: 'employee.onboard_list',
      subMenu: [
        {
          id: 'employee.onboard_list',
          permission: 'employee.onboard_list',
          title: t('menu:employees.onboarding'),
          navLink: '/employee/onboarding'
        },
        {
          id: 'employee.list',
          permission: 'employee.list',
          title: t('menu:employees.directory'),
          navLink: '/employee/directory'
        }
      ]
    },
    {
      id: 'payrollCycle',
      icon: 'payroll',
      title: t('menu:payroll.title'),
      permission: 'payrollCycle.list',
      subMenu: [
        {
          id: 'payrollCycle.list',
          permission: 'payrollCycle.list',
          title: t('menu:payroll.inProcess'),
          navLink: '/payroll'
        },
        {
          id: 'payrollCycle.approve',
          permission: 'payrollCycle.list',
          title: t('menu:payroll.history'),
          navLink: '/payroll/history'
        },
        {
          id: 'prefundBalance.list',
          permission: 'prefundBalance.list',
          title: t('menu:payroll.prefund'),
          navLink: '/payroll/prefund'
        }
      ]
    },
    {
      id: 'leave',
      icon: 'calendarLeave',
      title: t('menu:leave.leave'),
      permission: 'leave.list',
      subMenu: [
        {
          id: 'leave.request',
          permission: 'leave.list',
          title: t('menu:leave.request'),
          navLink: '/leave/request'
        },
        {
          id: 'leave.history',
          permission: 'leave.list',
          title: t('menu:leave.history'),
          navLink: '/leave/history'
        }
      ]
    },
    {
      id: 'attendance',
      icon: 'calendarcheckin',
      title: t('menu:attendance.attendance'),
      permission: 'leave.list',
      subMenu: [
        {
          id: 'leave.request',
          permission: 'leave.list',
          title: t('menu:attendance.submission'),
          navLink: '/attendance/submission'
        }
      ]
    },
    {
      id: 'workSchedule',
      icon: 'calendarSchedule',
      title: t('menu:workSchedule.title'),
      permission: 'workSchedule.view',
      subMenu: [
        {
          id: 'workSchedule.view',
          permission: 'workSchedule.view',
          title: t('menu:workSchedule.title'),
          navLink: '/work-schedule'
        }
      ]
    },
    {
      id: 'payrollComponent',
      icon: 'expense',
      title: t('menu:payComponents.title'),
      permission: 'payrollComponent.list',
      subMenu: [
        {
          id: 'payrollComponent.list',
          permission: 'payrollComponent.list',
          title: t('menu:payComponents.allowance'),
          navLink: '/payroll-component/allowance'
        },
        {
          id: 'payrollComponent.deduction',
          permission: 'payrollComponent.list',
          title: t('menu:payComponents.deduction'),
          navLink: '/payroll-component/deduction'
        }
      ]
    },
    {
      id: 'report',
      icon: 'document',
      title: t('menu:report.title'),
      permission: 'workSchedule.report',
      subMenu: [
        {
          id: 'report.daily',
          permission: 'workSchedule.report',
          title: t('menu:report.dailyAttendance'),
          navLink: '/report/daily-attendance'
        },
        {
          id: 'report.checkin',
          permission: 'workSchedule.report',
          title: t('menu:report.checkIn/Out'),
          navLink: '/report/attendance'
        },
        {
          id: 'report.employmentStatus',
          permission: 'employee.list',
          title: t('menu:report.employementStatus'),
          navLink: '/report/employment-status'
        },
        {
          id: 'report.bpjs',
          permission: 'payrollBpjs.list',
          title: 'BPJS',
          navLink: '/report/bpjs'
        },
        {
          id: 'report.pph',
          permission: 'payrollPph.list',
          title: t('menu:report.pph21'),
          navLink: '/report/pph-21'
        },
        {
          id: 'leave.list',
          permission: 'leave.list',
          title: t('menu:report.employeeLeave'),
          navLink: '/report/leave'
        }
      ]
    },
    {
      id: 'settings',
      icon: 'setting',
      title: t('menu:setting.settings'),
      permission: 'setting.company',
      subMenu: [
        {
          id: 'setting.company',
          permission: 'setting.company',
          title: t('menu:setting.company'),
          navLink: '/settings/company'
        },
        {
          id: 'setting.branch',
          permission: 'setting.company',
          title: t('menu:setting.branch'),
          navLink: '/settings/branch'
        },
        {
          id: 'setting.branch',
          permission: 'setting.company',
          title: t('menu:setting.notification'),
          navLink: '/settings/notification'
        },
        {
          id: 'leave.setting',
          permission: 'leave.setting',
          title: t('menu:setting.leave'),
          navLink: '/settings/leave'
        },
        {
          id: 'attendance.setting',
          permission: 'attendance.setting',
          title: t('menu:setting.attendance'),
          navLink: '/settings/attendance'
        },
        {
          id: 'approval.list',
          permission: 'approval.list',
          title: t('menu:setting.approval'),
          navLink: '/settings/approval'
        },
        {
          id: 'companyHoliday.view',
          permission: 'companyHoliday.view',
          title: t('menu:setting.holiday'),
          navLink: '/settings/holiday'
        },
        {
          id: 'overtime.list',
          permission: 'overtime.list',
          title: t('menu:setting.overtime'),
          navLink: '/settings/overtime'
        }
      ]
    }
  ];

  return { navigationConfig };
};
