import { localStorageService } from '@core/utils/localStorage';
import {
  EnglishAttendanceSubmission,
  EnglishChangeEmail,
  EnglishCheckInOut,
  EnglishCommon,
  EnglishDailyAttendance,
  EnglishDirectory,
  EnglishEditProfile,
  EnglishEmployee,
  EnglishEmployeeLeave,
  EnglishEmploymentStatus,
  EnglishLeaveHistory,
  EnglishLoginPage,
  EnglishMenu,
  EnglishOnboarding,
  EnglishPayroll,
  EnglishPayrollEmployee,
  EnglishReportBpjs,
  EnglishReportPph21,
  EnglishResetPassword,
  EnglishResetPin,
  EnglishSettingApproval,
  EnglishSettingAttendance,
  EnglishSettingCompany,
  EnglishSettingHoliday,
  EnglishSettingLeave,
  EnglishSettingNotification,
  EnglishSettingOvertime,
  EnglishSettingsBranch,
  EnglishtPaycomponent,
  EnglishWorkSchedule
} from '@i18n/locales/en';
import {
  BahasaAttendanceSubmission,
  BahasaChangeEmail,
  BahasaCheckInOut,
  BahasaCommon,
  BahasaDailyAttendance,
  BahasaDirectory,
  BahasaEditProfile,
  BahasaEmployee,
  BahasaEmployeeLeave,
  BahasaEmploymentStatus,
  BahasaLeaveHistory,
  BahasaLoginPage,
  BahasaMenu,
  BahasaOnboarding,
  BahasaPaycomponent,
  BahasaPayroll,
  BahasaPayrollEmployee,
  BahasaReportBpjs,
  BahasaReportPph21,
  BahasaResetPassword,
  BahasaResetPin,
  BahasaSettingApproval,
  BahasaSettingAttendance,
  BahasaSettingCompany,
  BahasaSettingHoliday,
  BahasaSettingLeave,
  BahasaSettingNotification,
  BahasaSettingOvertime,
  BahasaSettingsBranch,
  BahasaWorkSchedule
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    common: EnglishCommon,
    profile: EnglishEditProfile,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    onboarding: EnglishOnboarding,
    payroll: EnglishPayroll,
    resetPassword: EnglishResetPassword,
    settingBranch: EnglishSettingsBranch,
    resetPin: EnglishResetPin,
    settingCompany: EnglishSettingCompany,
    changeEmail: EnglishChangeEmail,
    settingOvertime: EnglishSettingOvertime,
    settingLeave: EnglishSettingLeave,
    leaveHistory: EnglishLeaveHistory,
    settingAttendance: EnglishSettingAttendance,
    settingNotification: EnglishSettingNotification,
    employee: EnglishEmployee,
    settingHoliday: EnglishSettingHoliday,
    payComponent: EnglishtPaycomponent,
    directory: EnglishDirectory,
    payrollEmployee: EnglishPayrollEmployee,
    workSchedule: EnglishWorkSchedule,
    attendanceSubmission: EnglishAttendanceSubmission,
    employmentStatus: EnglishEmploymentStatus,
    reportBpjs: EnglishReportBpjs,
    employeeLeave: EnglishEmployeeLeave,
    reportPph21: EnglishReportPph21,
    checkInOut: EnglishCheckInOut,
    dailyAttendance: EnglishDailyAttendance,
    settingApproval: EnglishSettingApproval
  };

  const bahasaResources = {
    common: BahasaCommon,
    profile: BahasaEditProfile,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    onboarding: BahasaOnboarding,
    payroll: BahasaPayroll,
    resetPassword: BahasaResetPassword,
    settingBranch: BahasaSettingsBranch,
    resetPin: BahasaResetPin,
    settingCompany: BahasaSettingCompany,
    changeEmail: BahasaChangeEmail,
    settingOvertime: BahasaSettingOvertime,
    settingLeave: BahasaSettingLeave,
    leaveHistory: BahasaLeaveHistory,
    settingAttendance: BahasaSettingAttendance,
    settingNotification: BahasaSettingNotification,
    settingHoliday: BahasaSettingHoliday,
    employee: BahasaEmployee,
    payComponent: BahasaPaycomponent,
    directory: BahasaDirectory,
    payrollEmployee: BahasaPayrollEmployee,
    workSchedule: BahasaWorkSchedule,
    attendanceSubmission: BahasaAttendanceSubmission,
    employmentStatus: BahasaEmploymentStatus,
    reportBpjs: BahasaReportBpjs,
    employeeLeave: BahasaEmployeeLeave,
    reportPph21: BahasaReportPph21,
    checkInOut: BahasaCheckInOut,
    dailyAttendance: BahasaDailyAttendance,
    settingApproval: BahasaSettingApproval
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: [
      'common',
      'profile',
      'login',
      'menu',
      'onboarding',
      'payroll',
      'payrollEmployee',
      'resetPassword',
      'settingBranch',
      'settingHoliday',
      'settingOvertime',
      'settingNotification',
      'leaveHistory',
      'payComponent',
      'workSchedule',
      'employmentStatus',
      'employeeLeave',
      'reportPph21',
      'checkInOut',
      'dailyAttendance'
    ]
  };
  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};
