import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import { ResponseMeInterface } from '@interface/UserInterface';
import { AxiosResponse } from 'axios';

export const getUserLogout = async (): Promise<ResponseMeInterface> => {
  return axiosAuthInstance
    .get('/logout')
    .then((res: AxiosResponse<ResponseMeInterface>) => res.data)
    .catch((err) => err);
};
