/* eslint-disable react-hooks/exhaustive-deps */
import { localStorageService } from '@core/utils/localStorage';
import { useGetUnreadCountService } from '@hooks/useGetNotificationService';
import { NotificationContextInterface } from '@interface/NotificationInterface';
import { createContext, ReactNode, useContext, useState } from 'react';

import { useAuth } from './AuthContext';

export const NotificationContext = createContext<NotificationContextInterface>({
  totalUnread: 0,
  setTotalUnread: () => undefined,
  handleRefetchUnreadCount: () => undefined,
  redirectUrl: '',
  activeBranchId: '',
  setActiveBranchId: () => undefined,
  setRedirectUrl: () => undefined
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { userAuth, activeCompany } = useAuth();
  const companyId =
    JSON.parse(localStorageService.getActiveCompany('active_company') || '{}').id ||
    activeCompany?.id;

  const [totalUnread, setTotalUnread] = useState<number>(0);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [activeBranchId, setActiveBranchId] = useState<string>('');

  const { refetch: refetchUnreadCount } = useGetUnreadCountService({
    params: {
      targetUserId: userAuth?.id as string,
      company_id: companyId,
      subscriber: 'gajicermat'
    },
    options: {
      enabled: !!userAuth?.id && !!companyId,
      refetchInterval: 10000,
      onSuccess: ({ data }) => {
        setTotalUnread(data.count);
      }
    }
  });

  const handleRefetchUnreadCount = () => {
    refetchUnreadCount();
  };

  return (
    <NotificationContext.Provider
      value={{
        totalUnread,
        redirectUrl,
        activeBranchId,
        setTotalUnread,
        handleRefetchUnreadCount,
        setActiveBranchId,
        setRedirectUrl
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
