import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { BaseText } from 'uangcermat-web-toolkit-v2';

const ToastUndoNotificationComponent = ({
  isShowToast,
  isError,
  toastMsg,
  isShowBtnMore,
  handleActionButton
}: {
  isShowToast: boolean;
  isError: boolean;
  toastMsg: string;
  handleActionButton: (values: string) => void;
  isShowBtnMore: boolean;
}) => {
  const { t } = useTranslation(['common']);

  if (isShowToast) {
    return (
      <div
        className={twMerge(
          'absolute flex-col h-[64px] flex-1 w-full left-0',
          isShowBtnMore ? 'bottom-16' : 'bottom-5'
        )}
      >
        <div
          className={twMerge(
            'mx-4 p-4 rounded-lg drop-shadow',
            !isError ? 'bg-light-whiteSmoke' : 'bg-red-linenRed'
          )}
        >
          <div>
            <BaseText label={toastMsg} className="text-[10px] text-dark-blackCoral font-semibold" />
          </div>
          {!isError ? (
            <div>
              <a className="cursor-pointer" onClick={() => handleActionButton('undo')}>
                <BaseText
                  label={t('common:undo')}
                  className="text-hyperlink-color-default hover:underline text-xs font-normal decoration-hyperlink-color-default"
                />
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export default ToastUndoNotificationComponent;
