import { NotificationGroupInterface } from '@interface/NotificationInterface';
import { getInitialNameForFallbackAvatar } from '@utils/getInitialName';
import { Avatar, BaseText, Checkbox, ColorDark, ColorLight } from 'uangcermat-web-toolkit-v2';

const ListNotificationComponent = ({
  isModify,
  notificationData,
  onClickListDetail,
  notificationSelected
}: {
  isModify: boolean;
  notificationData: NotificationGroupInterface[] | null;
  onClickListDetail: (nId: string, actions: string | undefined) => void;
  notificationSelected: string[];
}) => {
  return (
    <>
      {notificationData?.map((item, index) => (
        <div key={index}>
          <div className="py-2 px-4">
            <BaseText
              label={item.title}
              className="font-normal text-[10px] text-dark-bermudaGray"
            />
          </div>
          {item.list?.map((item, index) => (
            <div
              key={index}
              className="flex flex-row items-center py-2 px-4 gap-x-2.5 cursor-pointer gap-y-2"
              onClick={() => {
                onClickListDetail(item.id, item.action);
              }}
            >
              {isModify && (
                <Checkbox
                  key={'singleCheckBox'}
                  checked={notificationSelected?.find((x) => x === item?.id) as unknown as boolean}
                  variant="small"
                  onChange={() => {
                    onClickListDetail(item.id, item.url);
                  }}
                />
              )}
              {item.avatarUrl ? (
                <Avatar
                  src={item.avatarUrl}
                  size="medium"
                  variant="square"
                  fallback="text"
                  fallbackText={getInitialNameForFallbackAvatar({ name: item.title, length: 1 })}
                  fallbackBgColor={ColorDark.gumbo}
                />
              ) : (
                <div className="flex w-10 h-10 items-center justify-center bg-dark-gumbo rounded">
                  <BaseText
                    label={getInitialNameForFallbackAvatar({ name: item.title, length: 1 })}
                    variant="extra-large"
                    className="font-semibold"
                    color={ColorLight.whiteSolid}
                  />
                </div>
              )}
              <div className="relative flex flex-1 flex-col gap-0.5">
                <BaseText
                  label={item.title}
                  className="text-[10px] font-semibold"
                  color={
                    item.isNewData
                      ? 'var(--notificationPanel-usernameText-default)'
                      : 'var(--notificationPanel-usernameText-readed)'
                  }
                />
                <BaseText
                  label={item.description}
                  className="text-[10px] font-normal"
                  color={
                    item.isNewData
                      ? 'var(--notificationPanel-description-default)'
                      : 'var(--notificationPanel-description-readed)'
                  }
                />
                <BaseText
                  label={item.time}
                  className="text-[10px] font-normal"
                  color={
                    item.isNewData
                      ? 'var(--notificationPanel-time-default)'
                      : 'var(--notificationPanel-time-readed)'
                  }
                />
                {item.isNewData ? (
                  <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-notificationPanel-newNotificationIndicator-default rounded-full" />
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default ListNotificationComponent;
